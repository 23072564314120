<template>
  <div v-if="user">
    <v-toolbar class="header">
      <v-btn depressed color="primary" @click="navigateTo('Product')">
        <v-icon>mdi-keyboard-return</v-icon>
        兌換商品
      </v-btn>
      <v-spacer></v-spacer>
      <div class="text-center font-weight-bold mt-0">
        目前點數: {{ user ? user.balance : 0 }}點
      </div>
    </v-toolbar>
    <div class="text-center text-h6 mt-4">七天內兌換記錄</div>
    <div class="pa-4">
      <div class="text-center" v-if="!historys.length">目前無記錄</div>
      <v-expansion-panels accordion v-else>
        <v-expansion-panel v-for="(row, i) in historys" :key="i">
          <v-expansion-panel-header>
            {{ row.createdAt }} - {{ type(row) }}
            {{ isNewType(row) ? getProductsList(row?.payload) : "" }}
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="isNewType(row)" class="font-small">
            <div>點數異動: {{ row.point }} 點</div>
            <div>帳號點數: {{ row.balance }} 點</div>
            <v-row no-gutters>
              <v-col cols="auto" class="pr-1">兌換內容:</v-col>
              <v-col>
                <div>
                  <div v-for="item of row.payload">
                    {{ item.product.name }} x {{ item.amount }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-else class="font-small">
            <div>
              點數異動: {{ (type(row) == "現場兌換" ? 1 : -1) * row.point }} 點
            </div>
            <div>帳號點數: {{ row.balance }} 點</div>
            <v-row no-gutters>
              <v-col cols="auto" class="pr-1">兌換內容:</v-col>
              <v-col>
                <div v-if="type(row) == '現場兌換'">
                  <div v-for="product of row.payload">
                    {{ product.商品.商品名稱 }} x {{ product.數量 }}
                  </div>
                </div>
                <div
                  v-else-if="!row.payload.product && row.payload.items"
                  class="d-flex flex-column"
                >
                  <span
                    v-for="(product, index) in getOrderSum(row.payload.items)"
                    :key="index"
                  >
                    {{ product.商品名稱 }} x {{ product.amount }}
                  </span>
                </div>
                <div v-else>
                  {{ row.payload.product.商品名稱 }} x {{ row.payload.number }}
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import login from "@/mixins/login";
export default {
  name: "History",
  mixins: [login],
  data: () => ({
    historys: [],
    previousTheme: false,
  }),
  async created() {},
  mounted() {
    this.previousTheme = this.$vuetify.theme.dark;
    this.$vuetify.theme.dark = true;
  },
  beforeDestroy() {
    this.$vuetify.theme.dark = this.previousTheme;
  },
  methods: {
    isNewType(row) {
      return row.payload && row.payload[0] && !!row.payload[0].product;
    },
    async getHistory() {
      try {
        this.$vloading.show();
        let payload = { store: this.store, token: this.token };
        let { data } = await this.axios.post(`/getHistory`, payload);
        this.historys = data.map((log) => {
          if (Array.isArray(log.payload)) {
            return log;
          }
          return { ...log, payload: log.payload };
        });
      } catch (error) {
        alert(error);
      } finally {
        this.$vloading.hide();
      }
    },
    type(row) {
      switch (true) {
        case row.type == "線上一番賞":
          return "線上一番賞";
        case this.isNewType(row) && row.payload[0].product.name === "現金":
          return "儲存點數";
        case this.isNewType(row) && row.point > 0:
          return "兌換點數";
        case this.isNewType(row) && row.point <= 0:
          return "兌換商品";
        case row.type == "現場兌換":
        case row.type == "點數兌換":
          return "現場兌換";
        case row.status == "strike":
          return "點數補回";
        case row.status == "cancel":
          return "商品兌換(取消)";
        case row.type == "商品兌換":
        case row.point < 0:
          return "商品兌換";
      }
    },
    getOrderSum(items) {
      const group = _.groupBy(items, (product) => product.id);
      return Object.values(group).map((array) => {
        return { ...array[0], amount: array.length };
      });
    },
    getProductsList(products) {
      if (!Array.isArray(products) || products.length === 0) return "";
      return products.reduce((acc, item, index) => {
        return acc + (index !== 0 ? ", " : "") + item?.product.name;
      }, "- ");
    },
    navigateTo(name) {
      this.$router.push({ name, query: { store: "@695yimoc" } });
      return;
    },
  },
  watch: {
    user(val) {
      if (val) this.getHistory();
    },
  },
};
</script>

<style lang="scss" scoped>
.font-small {
  font-size: 15px;
}
</style>
